












































    import { Component, Vue } from 'vue-property-decorator';
    import AppContainerBase from '@/components/util/AppContainerBase.vue';
    import toolbarFilters from '@/components/library/toolbarFilters.vue';
    import dataTableSelection from '@/components/library/dataTableSelection.vue';
    import Util from '@/utils/Util';
    import { internet } from '@/utils/Internet';

    @Component({
        name: 'MainAudioSearch',
        components: {
            AppContainerBase,
            toolbarFilters,
            dataTableSelection
        },
    })

	export default class MainAudioSearch extends Vue {
        public titulo = this.$t("BibliotecaAudioSearch.titulo");
        public menu = false;
        public date = false;
        public date2 = false;
        public menu2 = false;
        public datos = [];
        // Filtro
        public filtroFechaInicial = "";
        public filtroFechaFinal = "";
        public filtroCallCenter = "";
        public filtroCampania = "";
        public filtroAgente = "";
        public renderToolbar = 1000;
        // Tabla
        public renderTable = 0;
        public callCenters = [];
        public campanias = [];
        public agentes = [];
        public isLoading = false;

        mounted() {
            let todos = this.$t("BibliotecaAudioSearch.todos") as string;
            this.filtroCallCenter = todos;
            this.filtroCampania = todos;
            this.filtroAgente = todos;
            this.setDates();
            console.log("fInicial", this.filtroFechaInicial);
            console.log("fFinal", this.filtroFechaFinal);
            this.getData();
        }

        private getData() {
            this.isLoading = true;
            const request = internet
                .newRequest()
                .get(`biblioteca/getBibliotecaNuevo?fechaInicial=${this.filtroFechaInicial}&fechaFinal=${this.filtroFechaFinal}`)
                .then((response) => {
                    this.datos = response.data;
                    //console.log(this.datos);
                    this.renderTable += 1;
                })
                .finally(() => {
					this.isLoading = false;
				});
        }

        public getAudios(data: any) {
            console.log(data);
            const request = internet
                .newRequest()
                .get(`biblioteca/getAudios?fechaInicial=${this.filtroFechaInicial}&fechaFinal=${this.filtroFechaFinal}&callCenter=${data["Call_center"]}&campania=${data["Campania"]}&agente=${data["Agente"]}`)
                .then((response) => {
                    this.datos = response.data;
                    //console.log(this.datos);
                    this.renderTable += 1;
                })
        }

        public getDestinatario(data: any) {
            console.log(data);
            const request = internet
                .newRequest()
                .get(`biblioteca/getAudiosDestinatario?fechaInicial=${this.filtroFechaInicial}&fechaFinal=${this.filtroFechaFinal}&${data["tipo"]}=${data["valor"]}`)
                .then((response) => {
                    this.datos = response.data;
                    //console.log(this.datos);
                    this.renderTable += 1;
                })
        }

        private setDates() {
			let today = new Date();
			let lastMonth = new Date();
			lastMonth.setMonth(today.getMonth() - 1);
			this.filtroFechaFinal = Util.dateToYYYYMMDD(today);
			this.filtroFechaInicial = Util.dateToYYYYMMDD(lastMonth);
		}

        public loadLibrary(data: any) {
            //console.log("loadLibrary", data);
            this.callCenters = data["callCenters"];
            this.campanias = data["campanias"];
            this.agentes = data["agentes"];
            this.renderToolbar += 1;
        }

        public actualizarFiltro(data: any) {
            console.log("actualizarFiltro", data);
            switch(data["tipo"]) {
                case "callCenter":
                    this.filtroCallCenter = data["valor"];
                    break;
                case "campania":
                    this.filtroCampania = data["valor"];
                    break;
                case "agente":
                    this.filtroAgente = data["valor"];
                    break;
                case "dateTo":
                    this.filtroFechaInicial = data["valor"];
                    this.getData();
                    return;
                case "dateEnd":
                    this.filtroFechaFinal = data["valor"];
                    this.getData();
                    return;
            }
            this.renderTable += 1
        }
    }
